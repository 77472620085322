/* @import url("https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;700&display=swap"); */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: -webkit-fill-available;
  background-color: #fcfcfc !important;
}

html {
  height: -webkit-fill-available;
  background-color: #fcfcfc;
}

* {
  font-size: 0.94rem;
  /* font-family: "PT Sans", sans-serif; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.btn:focus,
.btn:active {
  outline: none !important;
  box-shadow: none !important;
}

.ant-popover-inner {
  border-radius: 10px !important;
}

.ant-modal-body {
  padding: 0px 0px !important;
}

@font-face {
  font-family: 'MYRIADPRO-REGULAR';
  src: local('MYRIADPRO-REGULAR'), url(./fonts/MYRIADPRO-REGULAR.OTF) format('opentype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
@font-face {
  font-family: 'MYRIADPRO-SEMIBOLD';
  src: local('MYRIADPRO-SEMIBOLD'), url(./fonts/MYRIADPRO-SEMIBOLD.OTF) format('opentype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
@font-face {
  font-family: 'SFPRODISPLAYREGULAR';
  src: local('SFPRODISPLAYREGULAR'), url(./fonts/SFPRODISPLAYREGULAR.OTF) format('opentype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

#root {
  background-color: #fcfcfc;
}
